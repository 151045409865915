// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-container {
  display: flex;
}

.left-panel {
  flex: 1 1;
}

.right-panel {
  flex: 1 1;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
}
p{
  font-family: 'Poppins';
}
button {
  font-family: 'Poppins', sans-serif !important; /* Set Poppins as the font */
}
a {
  font-family: 'Poppins', sans-serif; /* Set Poppins as the font */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,SAAO;AACT;AACA;EACE,iCAAiC;AACnC;AACA;EACE,sBAAsB;AACxB;AACA;EACE,6CAA6C,EAAE,4BAA4B;AAC7E;AACA;EACE,kCAAkC,EAAE,4BAA4B;AAClE","sourcesContent":[".app-container {\r\n  display: flex;\r\n}\r\n\r\n.left-panel {\r\n  flex: 1;\r\n}\r\n\r\n.right-panel {\r\n  flex: 1;\r\n}\r\nh1, h2, h3, h4, h5, h6 {\r\n  font-family: 'Oswald', sans-serif;\r\n}\r\np{\r\n  font-family: 'Poppins';\r\n}\r\nbutton {\r\n  font-family: 'Poppins', sans-serif !important; /* Set Poppins as the font */\r\n}\r\na {\r\n  font-family: 'Poppins', sans-serif; /* Set Poppins as the font */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
